import React from 'react'
import InputControl from '../../../components/InputControl'
import { Game, Keyboard, Setting4 } from 'iconsax-react'
import { IMaxSpeed } from '../../../types/general'

// import { Container } from './styles';

interface IProps {
    command: string
    imageLength: number
    toggleLight: () => void
    toggleFlash: () => void
    setModalConfig: (value: boolean) => void
    inputType: string
    speed: IMaxSpeed
}

const Remote: React.FC<IProps> = ({ command, imageLength, inputType, toggleFlash, toggleLight, setModalConfig, speed }) => {
    const renderInputType = () => (
        <div className="d-flex flex-row align-items-center justify-content-between">
            {inputType === 'KEYBOARD' ? (
                <InputControl iconType={<Keyboard size="20" />} inputType={inputType} />
            ) : (
                <InputControl iconType={<Game size="20" />} inputType={inputType} />
            )}
            <div>
                <button className="btn btn-light ms-3" onClick={() => setModalConfig(true)}>
                    <Setting4 size="14" />
                </button>
            </div>
        </div>
    )

    return (
        <div className="card card-body w-100">
            <div className="card-title">
                <strong>Controle Remoto</strong>
            </div>
            <div>{renderInputType()}</div>
            <p className="card-text mt-3">
                Comando: <code className="text-danger">{command}</code>
                <br />
                Velocidade Máxima: <code className="text-danger">{speed}</code>
                <br />
                Tamanho da imagem: <code className="text-danger">{imageLength} bytes</code>
            </p>
            <div className="btn-group">
                <button type="button" onClick={toggleLight} className="btn btn-outline-primary">
                    Farol
                </button>
                <button type="button" onClick={toggleFlash} className="btn btn-outline-primary">
                    Flash
                </button>
            </div>
        </div>
    )
}

export default Remote
