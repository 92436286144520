import React from 'react'
import { IDirection } from '../../types/general'

interface IProps {
    sendDirection: (direction: IDirection) => void
}

const GamePadMobile: React.FC<IProps> = ({ sendDirection }) => {
    React.useEffect(() => {
        document.querySelectorAll('.control').forEach((item) => {
            item.addEventListener('touchstart', touchStartHandler)
        })

        document.querySelectorAll('.control').forEach((item) => {
            item.addEventListener('touchend', touchEndHandler)
        })

        return () => {
            document.querySelectorAll('.control').forEach((item) => {
                item.removeEventListener('touchstart', touchStartHandler)
            })

            document.querySelectorAll('.control').forEach((item) => {
                item.removeEventListener('touchend', touchEndHandler)
            })
        }
    }, [])

    function touchStartHandler(event) {
        var direction = event.target.dataset.direction
        // console.log('Touch Start :: ' + direction)
        sendMessage(direction)
    }

    function touchEndHandler(event) {
        const stop_command = 'stop'
        // var direction = event.target.dataset.direction
        // console.log('Touch End :: ' + direction)
        sendMessage(stop_command)
    }

    const sendMessage = (message: IDirection) => {
        console.log('Sending Message :: ' + message)
        sendDirection(message)
    }

    return (
        <div className="w-full dark">
            <div className="d-flex justify-content-center mb-4">
                <nav className="o-pad">
                    <a className="up control" data-direction="up" />
                    <a className="right control" data-direction="left" />
                    <a className="down control" data-direction="down" />
                    <a className="left control" data-direction="right" />
                </nav>
            </div>
        </div>
    )
}

export default GamePadMobile
