import React from 'react'

// import { Container } from './styles';

interface IProps {
    tilt: number
    pan: number
    sendTiltPan: (tilt: number, pan: number) => void
    resetTiltPan: () => void
}

const CamControl: React.FC<IProps> = ({ pan, resetTiltPan, sendTiltPan, tilt }) => {
    return (
        <div className="card card-body w-100">
            <form>
                <div className="card-title">
                    <strong>Controle Camera</strong>
                </div>
                <div className="mb-3">
                    <label className="form-label">Tilt</label>
                    <input
                        type="range"
                        className="form-range"
                        onMouseLeave={() => resetTiltPan()}
                        min="0"
                        max="180"
                        step="1"
                        value={tilt}
                        onChange={(e) => sendTiltPan(Number(e.target.value), pan)}
                        id="customRange1"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Pan</label>
                    <input
                        type="range"
                        className="form-range"
                        onMouseLeave={() => resetTiltPan()}
                        min="0"
                        max="180"
                        step="1"
                        value={pan}
                        onChange={(e) => sendTiltPan(tilt, Number(e.target.value))}
                        id="customRange2"
                    />
                </div>
            </form>
        </div>
    )
}

export default CamControl
